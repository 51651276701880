import React, { useState } from 'react'
import P from "../../core/P"
import H2 from "../../core/H2"
import VideoPlay from '../../core/VideoPlay'
export default function TestimonialsListing({ testimonials, title }) {
    const [isOpen, setIsOpen] = useState(false)
    const [videoUrl, setVideoUrl] = useState(null)

    const handleVideo = e => {
        document.body.classList.add("overflow-hidden")
        setIsOpen(true)
        setVideoUrl(e)
    }

    const handleCloseVideo = (value) => {
        setIsOpen(value)
        document.body.classList.remove("overflow-hidden")
    }
    return (
        <>
            {isOpen && (
                <VideoPlay videoUrl={videoUrl} handleCloseVideo={handleCloseVideo} />
            )}
            <div className='bg-white w-full'>
                {title && (
                    <div className='text-center flex justify-center pt-20 pb-16'>
                        <H2
                            title={title}
                            className={`max-w-5xl`}
                        />
                    </div>
                )}
                <div className='flex flex-wrap max-w-screen-xl mx-auto'>
                    {testimonials?.map(({ TestimonialsCPT }, index) => {
                        return <div className='lg:w-1/2 w-full flex-shrink ' key={index}>
                            <div className='flex flex-col lg:px-20 lg:py-16 md:px-28 md:py-8 px-5 py-10'>
                                <div className='relative'>
                                    <div className="flex-shrink-0 md:block md:absolute relative lg:-left-20 lg:top-0 md:-left-20 md:top-0 left-0 top-0 mb-5">
                                        <img
                                            src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/06/Quotes.png" className="h-12 w-auto"
                                            alt="Play-Button"
                                            width="80px"
                                            height="80px"
                                            // title="Quote"
                                            loading="lazy"
                                        />
                                    </div>
                                    <P
                                        className="text-sm font-semibold text-shark-500 leading-10"
                                        noPad
                                        title={TestimonialsCPT?.review}
                                    />
                                </div>
                                <div className='flex flex-col md:flex-row pt-8 justify-start'>
                                    <div className='h-16 w-16 flex-shrink-0 mr-4 lg:mb-0 mb-5'>
                                        {TestimonialsCPT?.videoLink ?
                                            <div role="button" className="relative" onClick={() => handleVideo(TestimonialsCPT?.videoLink)} aria-hidden="true">
                                                <img
                                                    className="mx-auto h-16 w-16 rounded-full cursor-pointer"
                                                    src={TestimonialsCPT?.avatar?.mediaItemUrl}
                                                    alt={TestimonialsCPT?.avatar?.altText ? TestimonialsCPT?.avatar?.altText : "Testimonial"}
                                                    width={TestimonialsCPT?.avatar?.mediaDetails?.width}
                                                    height={TestimonialsCPT?.avatar?.mediaDetails?.height}
                                                    // title="Testimonial"
                                                    loading="lazy"
                                                />
                                                <img
                                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Play-Button.png"
                                                    alt="Play-Button"
                                                    width="80px"
                                                    height="80px"
                                                    // title="Play-Button"
                                                    className="absolute bottom-0 -right-1 z-6 rounded-full w-6 cursor-pointer"
                                                    loading="lazy"
                                                />
                                            </div> :
                                            <img
                                                className="mx-auto h-16 w-16 rounded-full "
                                                src={TestimonialsCPT?.avatar?.mediaItemUrl}
                                                alt={TestimonialsCPT?.avatar?.altText ? TestimonialsCPT?.avatar?.altText : "Testimonial"}
                                                width={TestimonialsCPT?.avatar?.mediaDetails?.width}
                                                height={TestimonialsCPT?.avatar?.mediaDetails?.height}
                                                // title="Testimonial"
                                                loading="lazy"
                                            />
                                        }
                                    </div>
                                    <div className='flex-grow'>
                                        <P
                                            className="font-medium text-shark-500"
                                            noPad
                                            title={TestimonialsCPT?.name}
                                        />
                                        <P
                                            className="text-sm text-shark-200 font-medium mt-2"
                                            noPad
                                            title={TestimonialsCPT?.positionAndCompany}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}
